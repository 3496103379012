<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Logo"
          class="shrink mr-2 white"
          contain
          width="40"
          height="40"
          src="@/assets/logo.svg"
          transition="scale-transition"
        />
      </div>
      <v-container fluid fill-height>
        <v-row class="justify-center align-center">
          <v-col>
            <p class="text-md-h5 text-subtitle-2">Gorin Schedule 2020</p>
          </v-col>
        </v-row>
      </v-container>

      <v-spacer></v-spacer>

      <v-btn
        href="https://www.gorin.jp/"
        target="_blank"
        text
      >
        <span class="mr-2">gorin.jp</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  })
}
</script>
