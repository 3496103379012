<template>
  <v-row class="fill-height scroll-area">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
          >
            Today
          </v-btn>
          <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ formattedFocusDate }}
          </v-toolbar-title>
          <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-sheet>
      <v-sheet>
        <v-autocomplete
            v-model="selectedValues"
            :items="suggestionCategories"
            dense
            chips
            small-chips
            label="競技をフィルター"
            multiple
            solo
            @change="handleChangeAutocomplete"
        >
          <template v-slot:selection="data">
            <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="removeSelectedItem(data.item)"
            >
              {{ data.item }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-sheet>
      <v-sheet>
        <vue-custom-scrollbar>
          <v-sheet :width=getWidth() height="600">
            <v-calendar
                ref="calendar"
                v-model="focusDate"
                color="primary"
                type="category"
                category-days="1"
                locale="ja"
                :categories="viewCategories"
                :events="events"
                :event-color="getEventColor"
                @click:event="showEvent"
                @change="handleChangeCalendar"
            >
              <template v-slot:day-body="{ category }">
                <div
                    class="v-current-time"
                    :class="{ first: viewCategories[0] === category }"
                    :style="{ top: nowY }"
                ></div>
              </template>
            </v-calendar>
            <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
            >
              <v-card
                  color="grey lighten-4"
                  min-width="350px"
                  flat
              >
                <v-toolbar
                    :color="selectedEvent.color"
                    dark
                >
                  <v-toolbar-title>
                    {{ selectedEvent.name }}
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text v-if="selectedEvent.details">
                  <p class="font-weight-bold">{{ selectedEvent.category }}</p>
                  <p>{{ selectedEvent.details.name }}</p>
                  <p>開始時間: {{ selectedEvent.details.time }}</p>
                  <p>
                    配信URL: <a :href="selectedEvent.details.url" target="_blank">{{ selectedEvent.details.url }}</a>
                    <v-icon right>mdi-open-in-new</v-icon>
                  </p>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-sheet>
        </vue-custom-scrollbar>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

dayjs.locale('ja');

export default {
  components: {
    vueCustomScrollbar
  },
  data: function () {
    const now = dayjs()
    const categories = this.$store.getters.getCompetitionTitleListByDate(now.format('YYYY-MM-DD'))

    return {
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      focusDate: now.toDate(),
      events: [],
      selectedValues: [],
      suggestionCategories: categories,
      viewCategories: categories,

      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,

      ready: false,
    }
  },
  mounted() {
    this.$refs.calendar.checkChange()
    this.ready = true
    this.scrollToTime()
    this.updateTime()
  },
  methods: {
    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime() {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },

    setToday() {
      this.focusDate = dayjs().toDate()
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },

    handleChangeAutocomplete(newValues) {
      this.viewCategories = newValues.length === 0
          ? this.$store.getters.getCompetitionTitleListByDate(this.formattedFocusDate)
          : newValues
    },
    removeSelectedItem(item) {
      this.selectedValues = this.selectedValues.filter((e) => {
        return e !== item
      })
      this.handleChangeAutocomplete(this.selectedValues)
    },

    getWidth() {
      return this.viewCategories.length * 300;
    },
    getEventColor(event) {
      return event.color
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    handleChangeCalendar({ start, end }) {
      const events = []

      this.$store.getters.getScheduleByDate(this.formattedFocusDate).forEach((competition) => {
        competition.gameList.forEach((game) => {
          const startDayjs = dayjs(game.time)

          events.push({
            name: game.name,
            details: {
              name: game.name || '',
              time: game.time || '',
              url: game.url || '',
            },
            start: startDayjs.toDate(),
            end: startDayjs.add(30, 'minutes').toDate(),
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: true,
            category: competition.title,
          })
        })
      })

      this.suggestionCategories = this.$store.getters.getCompetitionTitleListByDate(this.formattedFocusDate)
      this.viewCategories = this.$store.getters.getCompetitionTitleListByDate(this.formattedFocusDate)
      this.events = events
    },
  },
  computed: {
    cal() {
      return this.ready ? this.$refs.calendar : null
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },

    formattedFocusDate() {
      return dayjs(this.focusDate).format('YYYY-MM-DD')
    },
  }
}
</script>

<style lang="scss">
.scroll-area {
  position: relative;
  margin: auto;
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
