import Vue from 'vue'
import Vuex from 'vuex'
import schedule from '@/assets/schedule.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    schedule: schedule,
    competitionTitleSetMap: new Map(),
  },
  getters: {
    getScheduleByDate: (state) => {
      return (dateString) => {
        return state.schedule[dateString]
      }
    },
    getCompetitionTitleListByDate: (state) => {
      return (dateString) => {
        if (state.competitionTitleSetMap.has(dateString)) {
          return [...state.competitionTitleSetMap.get(dateString)]
        }

        const set = new Set()
        state.schedule[dateString].forEach((e) => {
          set.add(e.title)
        })
        state.competitionTitleSetMap.set(dateString, set)

        return [...set]
      }
    }
  },
  mutations: {},
  actions: {},
  modules: {}
})
