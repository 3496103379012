<template>
  <index />
</template>

<script>
import Index from '../components/Index'

export default {
  name: 'Home',

  components: {
    Index
  }
}
</script>
